exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bantuan-jsx": () => import("./../../../src/pages/bantuan.jsx" /* webpackChunkName: "component---src-pages-bantuan-jsx" */),
  "component---src-pages-fitur-jsx": () => import("./../../../src/pages/fitur.jsx" /* webpackChunkName: "component---src-pages-fitur-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kompetisi-sekolah-jsx": () => import("./../../../src/pages/kompetisi-sekolah.jsx" /* webpackChunkName: "component---src-pages-kompetisi-sekolah-jsx" */),
  "component---src-pages-press-jsx": () => import("./../../../src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-school-competition-schedule-jsx": () => import("./../../../src/pages/school-competition-schedule.jsx" /* webpackChunkName: "component---src-pages-school-competition-schedule-jsx" */),
  "component---src-pages-tentang-kami-jsx": () => import("./../../../src/pages/tentang-kami.jsx" /* webpackChunkName: "component---src-pages-tentang-kami-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */)
}

